.sidebar{
    flex: 1;
    height: calc(100vh - 60px);
    position: sticky;
    top: 50px;
    background-color: rgb(0, 129, 167);
}

.sidebarWrapper{
    padding: 20px;
    color: #555;
}

.sidebarMenu{
    margin-bottom: 20px;
}

.sidebarTitle{
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.sidebarList{
    list-style: none;
    padding: 5px;
}

.sidebarListItem{
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: white;
}

.sidebarListItem.active, .sidebarListItem:hover{
    background-color: rgba(3, 70, 90, 0.711);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}