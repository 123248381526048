.login{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.loginLogo{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 60%;
    padding: 20px 10px;
    border-radius: 5px;
    gap: 20px;
    border: 1px solid lightgray;
    background-color: rgb(238, 238, 238);
}

.loginForm > .title{
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.loginInput{
    margin-bottom: 15px;
    padding: 5px;
    width: 95%;
    height: 30px;
    border: none;
    outline: none;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,.5);
    font-size: 20px;
}

.loginButton{
    background-color: rgb(0, 166, 255);
    color: rgb(255, 246, 242);
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 50%;
    transition: all .3s ease;
    border-radius: 4px;
}

.loginButton:hover{
    background-color: rgb(2, 113, 172);
}