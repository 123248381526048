.logout{
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoutButton{
    padding: 15px 30px;
    background-color: teal;
    border: none;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    color: bisque;
    font-size: 1.2rem;
    transition: all .3s;
}

.logoutButton:hover{
    background-color: rgb(0, 43, 128);
    color: beige;
    font-size: 1.2rem;
}